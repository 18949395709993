<template>
  <div>
    <loading
    :active.sync="dados.isLoading"
    :can-cancel="false"
    :is-full-page="true"
    color="#f68b29"/> 

    <div class='botoes'>
      <b-button
        type="button"
        variant="primary"
        size="sm"
        class="btnProcessar"
        :disabled="dados.disabledCampos"
        @click="dados.filtro_opened = !dados.filtro_opened"><v-icon scale="0.8" name="filter"/> Filtro</b-button>
    </div>

    <div v-if="dados.filtro_opened">
      <b-form class="filtro" @submit.prevent="listarDados(1)">
        <InputBasico
          label="Data Inicial"
          name="fieldDataInicial"
          size="sm"
          info="Data baseada no início do processamento"
          v-model="dados.item.dataInicial"
          v-validate="{ required:true}"
          :disabled="dados.disabledCampos"
          min=1
          max=12
          type="date"/>
        <InputBasico
          label="Data Final"
          name="fieldDataFinal"
          info="Data baseada no início do processamento"
          size="sm"
          v-model="dados.item.dataFinal"
          :disabled="dados.disabledCampos"
          type="date"/>

        <b-button
          type="submit"
          variant="primary"
          size="sm"
          class="btnProcessar"
          :disabled="dados.disabledCampos"><v-icon scale="0.8" name="search"/> Buscar</b-button>
      </b-form>
    </div>

    <label v-if="dados.isConcluido" class="concluido loaderCheck">Concluído com sucesso!</label>

    <div class="loader">
      <Processando v-if="dados.show_loader" mensagem="Carregando dados da Base..."/>
      <Processando v-if="dados.show_processing" mensagem="Processando requisição..."/>
    </div>

    <div v-if="dados.show_loader !== true">
      <h5>Resultado da busca</h5>

      <div class="resultado" v-if="dados.total > 0">
        {{dados.total > 1 ? 'Encontradas':'Encontrada'}} <strong>{{dados.total}}</strong> {{dados.total > 1 ? 'ocorrências':'ocorrência'}}.
      </div>

      <div v-if="dados.listaItens.length > 0">
        <b-table
          striped
          hover
          bordered
          small
          stacked="md"
          head-variant="dark"
          :items="dados.listaItens"
          :fields="dados.fields"
          :tbody-tr-class="rowClass">
          
          <template #head(actions)="data">
            <center>Ações</center>
          </template>
          
          <template #cell(actions)="row">
            <center>
              <b-button :disabled="!row.item.parametros" class="btn btn-success btnAction" size="sm" @click="row.toggleDetails" title="Visualizar parâmetros de integração"><v-icon scale="1" :name="row.detailsShowing ? 'clipboard':'clipboard-list'"/></b-button>
              <b-button :disabled="validaJson(row.item)" class="btn btn-danger btnAction" size="sm" v-b-modal.viewLog @click="selecionarItem(row.item)" title="Ver ocorrências de envio"><v-icon scale="1" name="eye"/></b-button>
            </center>
          </template>
          
          <template #cell(dataInicio)="row">
            {{retornaDataFormatadaTabelaConsulta(row.item.dataInicio)}}
          </template>

          <template #cell(dataFinal)="row">
            {{retornaDataFormatadaTabelaConsulta(row.item.dataFinal)}}
          </template>

          <template #row-details="row">
            <b-card class="detalhe">
              <span>Parâmetros</span>

              <b-row class="sm-2" v-if="row.item.parametros.tipo">
                <b-col sm="2" class="text-sm-right"><b>Tipo:</b></b-col>
                <b-col>{{row.item.parametros.tipo}}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.date_low">
                <b-col sm="2" class="text-sm-right"><b>Data Inicial:</b></b-col>
                <b-col>{{row.item.parametros.date_low}}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.date_high">
                <b-col sm="2" class="text-sm-right"><b>Data Final:</b></b-col>
                <b-col>{{row.item.parametros.date_high}}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.branch && row.item.parametros.branch.length > 0">
                <b-col sm="2" class="text-sm-right"><b>Documentos:</b></b-col>
                <b-col>{{arrayParaString(row.item.parametros.branch,"filial")}}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.listaContabil && row.item.parametros.listaContabil.length > 0">
                <b-col sm="2" class="text-sm-right"><b>Lotes contábil:</b></b-col>
                <b-col>{{arrayParaString(row.item.parametros.listaContabil,"lote")}}</b-col>
              </b-row>

            </b-card>
          </template>
        </b-table>

        <div id="footerTable">
          <div>
            <b-form-select v-model="paginacao.limitePorPagina" size="sm" :options="paginacao.limitOptions" @change="listarDados(1)"></b-form-select>
          </div>
          
          <div class="paginacao" v-if="dados.listaItens.length > 0">
            <b-pagination
              class="paginacao__seletor"
              :total-rows="paginacao.totalDePaginas"
              :per-page="paginacao.limitePorPagina"
              v-model="paginacao.paginaAtual"
              @input="mudarPagina"
              size="sm"/>
          </div>      
        </div>
      </div>
      <div v-else>
        Nenhum registro encontrado.
      </div>
    </div>

    <b-modal 
      id="viewLog" 
      title="Ocorrências de integração" 
      size="xl">
      <div>
        <b-tabs small content-class="mt-3">
          <b-tab title="Lançamentos" active>
            <template #title>
              Lote Contábil <b-spinner small label="Small Spinner" type="grow" variant="danger" v-if="dados.itemSelecionado.retornoLoteContabil.length > 0"/>
            </template>
            <b-table 
              striped 
              hover 
              bordered 
              head-variant="dark"
              stacked="md"
              small
              v-if="dados.itemSelecionado.retornoLoteContabil && dados.itemSelecionado.retornoLoteContabil.length > 0" 
              :items="dados.itemSelecionado.retornoLoteContabil" 
              :fields="dados.fieldsLote">

              <template #head(actions)="data">
                <center>Ações</center>
              </template>
              
              <template #cell(actions)="row">
                <center>
                  <b-button class="btn btn-success btnAction" size="sm" v-b-modal.viewJsonLote @click="selecionarJson(row.item)" title="Ver dados completo"><v-icon scale="1" name="eye"/></b-button>
                </center>
              </template>
            </b-table>
            <span v-if="dados.itemSelecionado.retornoLoteContabil && dados.itemSelecionado.retornoLoteContabil.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          <b-tab title="Contas Inexistentes">
            <template #title>
              Contas <b-spinner small label="Small Spinner" type="grow" variant="danger" v-if="dados.itemSelecionado.contasInexistentes.length > 0"/>
            </template>
            <label>
              Os lançamentos abaixo não foram integrados ao sistema por falta de informação da conta em nosso de-para de <a :href="`/depara/contacontabil/${auth}`">Conta Contábil</a>. Favor cadastrar as contas e reintegrar os lançamentos e garantir que a mesma informaço está cadastrada no destino.
            </label>
            <b-table 
              striped 
              hover 
              bordered 
              head-variant="dark"
              stacked="md"
              small
              v-if="dados.itemSelecionado.contasInexistentes.length > 0" 
              :items="dados.itemSelecionado.contasInexistentes" 
              :fields="dados.fieldsContas">
            </b-table>
            <span v-if="dados.itemSelecionado.contasInexistentes.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          <b-tab title="Lotes não integrados">
            <template #title>
              Lotes não integrados <b-spinner small label="Small Spinner" type="grow" variant="danger" v-if="dados.itemSelecionado.retornoLoteNaoGerado && dados.itemSelecionado.retornoLoteNaoGerado.length > 0"/>
            </template>

            <b-alert :show="dados.itemSelecionado.retornoLoteNaoGerado && dados.itemSelecionado.retornoLoteNaoGerado.length > 0 && !dados.itemSelecionado.lotesReintegrado" variant="danger">
              <div class="alerta">
                <div class="alertaTexto">
                  Os lotes abaixo não foram integrados por algum problema de comunicação entre os servidores.<br>
                  Caso deseje reintegrar apenas estes lotes, clique no botão ao lado.
                </div>
                <div class="alertaBotao">
                  <b-button size="sm" variant="danger" @click="reintegrar(dados.itemSelecionado)">Reintegrar</b-button>
                </div>
              </div>
            </b-alert>

            <b-alert :show="dados.itemSelecionado.lotesReintegrado" variant="warning">
              <div class="alerta">
                <div class="alertaTexto">
                  Os lotes abaixo já foram reintegrados.
                </div>
              </div>
            </b-alert>

            <b-table 
              striped 
              hover 
              bordered 
              head-variant="dark"
              stacked="md"
              small
              v-if="dados.itemSelecionado.retornoLoteNaoGerado && dados.itemSelecionado.retornoLoteNaoGerado.length > 0" 
              :items="dados.itemSelecionado.retornoLoteNaoGerado" 
              :fields="dados.fieldsNaoInteg">
            </b-table>
            <span v-if="dados.itemSelecionado.retornoLoteNaoGerado && dados.itemSelecionado.retornoLoteNaoGerado.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          
        </b-tabs>
      </div>
    </b-modal>

    <b-modal 
      id="viewJsonLote" 
      title="Dados do Lote" 
      size="xl">
      <div>
        <vue-json-pretty
          class="textareaRetorno"
          :showLine="true"
          :showDoubleQuotes="true"
          :highlightMouseoverNode="true"
          :collapsedOnClickBrackets="true"
          :data="dados.selJsonLote"></vue-json-pretty>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {processaLancamentos} from '../../../common/services/lancamentosService';
import {getLogLancamentoContab} from '../../../common/services/logsService';
import InputBasico from '../../../components/inputs/InputBasico';
import Processando from '../../../components/comuns/Processando';
import * as dataHelper from '../../../common/helpers/DataHelper';
import * as arrayHelper from '../../../common/helpers/ArrayHelper';
import { getUserObj } from "@/common/localStorage/localStorage";
import VueJsonPretty from 'vue-json-pretty';

export default {
  data() {
    return {
      auth: null,
      dados: {
        empresa: null,
        isLoading: true,
        item : {
          dataInicial: undefined,
          dataFinal: undefined
        },
        notaEnvioView: {},
        disabledCampos: false,
        filtro_opened: true,
        data_loaded: false,
        show_loader: false,
        show_processing: false,
        selJsonLote: null,
        listaItens: [],
        itemSelecionado: {
          id: null,
          retornoLoteContabil: [],
          contasInexistentes: [],
          retornoLoteNaoGerado: []
        },
        fields: [
          { key: "dataInicio", label: "Inicio Processamento", sortable: true },
          { key: "dataFinal", label: "Fim Processamento", sortable: true },
          { key: "actions", label: "Ações", sortable: true }
        ],
        fieldsLote: [
          { key: "numLot", label: "Nº Lote", sortable: true },
          { key: "resultado", label: "Mensagem" },
          { key: "actions", label: "Ações" }
        ],
        fieldsContas: [
          { key: "cod_contabil", label: "Conta Contábil", sortable: true},
          { key: "conta_razao", label: "Razão"},
          { key: "item", label: "Lançamento"}
        ],
        fieldsNaoInteg: [
          { key: "codEmp", label: "Emp.", sortable: true},
          { key: "codFil", label: "Filial", sortable: true},
          { key: "datLot", label: "Data", sortable: true},
          { key: "desLot", label: "Lote", sortable: true},
          { key: "totLot", label: "Total", sortable: true}
        ]
      },
      paginacao: {
        paginaAtual: 1,
        limitePorPagina: 10,
        totalDePaginas: 0,
        limitOptions: [
          { value: 10, text: '10 por página' },
          { value: 25, text: '25 por página' },
          { value: 50, text: '50 por página' },
          { value: 75, text: '75 por página' },
          { value: 100, text: '100 por página' }
        ]
      }
    }
  },
  components: {
    InputBasico,
    Processando,
    VueJsonPretty
  },
  methods: {
    async listarDados(pagina) {
      if (this.validaData()) {
        this.dados.listaItens = [];
        this.dados.show_loader = true;
        this.dados.disabledCampos = true;

        const filtro = {};

        this.dados.item.codEmp = this.dados.empresa;

        Object.keys(this.dados.item).forEach((key)=>{
          if (this.dados.item[key]) {
            filtro[key] = this.dados.item[key];
          }
        });
          
        filtro.limite = this.paginacao.limitePorPagina, 
        filtro.pagina = pagina;

        const resultado = (await getLogLancamentoContab(filtro)).data;
        this.dados.listaItens = resultado.list;
        this.dados.total = resultado.total;
        this.paginacao.totalDePaginas = parseInt(this.paginacao.limitePorPagina * resultado.pages);
      }

      this.dados.show_loader = false;
      this.dados.disabledCampos = false;
      this.dados.isLoading = false;
    },
    async reintegrar(dados) {
      let lotes = [];
      dados.retornoLoteNaoGerado.forEach(l=>{
        lotes.push({
          empresa:getUserObj().empresa.idSAP,
          exercicio: l.datLot.substr(6,4),
          lote: l.desLot.substr(6,l.desLot.length)
        })
      });

      let dadosEnvio = {
        "date_low": "",
        "date_high": "",
        "id": dados.id,
        "listaContabil": lotes
      }
      console.log(dadosEnvio)
      let ret = await processaLancamentos(dadosEnvio);
    },
    async mudarPagina(p) {
      const {paginaAtual} = this.paginacao;
      this.listarDados(paginaAtual);
    },
    retornaDataFormatadaTabelaConsulta(data) {
      return dataHelper.retornaISOParaStringComMascara(data, 'DD/MM/YYYY - HH:mm:ss')
    },
    abrirEnvioSapiens(envio){
      this.dados.notaEnvioView = envio
    },
    arrayParaString(array,field) {
      return arrayHelper.arrayParaString(array,field);
    },
    validaJson(ArrayIn) {
      let ret = true;
      
      if (ArrayIn.contasInexistentes && ArrayIn.contasInexistentes.length > 0) ret = false;

      if (ArrayIn.retornoLoteContabil && ArrayIn.retornoLoteContabil.length > 0) ret = false;
      
      if (ArrayIn.retornoLoteNaoGerado && ArrayIn.retornoLoteNaoGerado.length > 0 && !ArrayIn.lotesReintegrado) ret = false;

      return ret;
    },
    rowClass(item, type) {      
      if (item && type === 'row') {
        if (!this.validaJson(item)) {
          return "table-danger"
        }
      }
    },
    selecionarItem(json) {
      this.dados.itemSelecionado = {
        id: null,
        lotesReintegrado:false,
        retornoLoteContabil: [],
        contasInexistentes: [],
        retornoLoteNaoGerado: []
      }
      this.dados.itemSelecionado.id = json._id;
      this.dados.itemSelecionado.retornoLoteContabil = json.retornoLoteContabil;
      this.dados.itemSelecionado.contasInexistentes = json.contasInexistentes;
      this.dados.itemSelecionado.retornoLoteNaoGerado = json.retornoLoteNaoGerado;
      this.dados.itemSelecionado.lotesReintegrado = json.lotesReintegrado;
    },
    selecionarJson(json) {
      this.dados.selJsonLote = json;
    },
    arrayParaString(array,field) {
      return arrayHelper.arrayParaString(array,field);
    },
    validaData() {
      const d1 = new Date(this.dados.item.dataInicial + 'T00:00:00');
      const d2 = new Date(this.dados.item.dataFinal + 'T23:59:00');

      if (!this.dados.item.dataInicial || !this.dados.item.dataFinal) {
        alert('É necessário selecionar um período.');
        return false;
      }
      
      if (d1 > d2) {
        alert('A data inicial deve ser menor que a data final.');
        return false;
      }
      
      return true;
    }
  },
  async created() {
    const local = JSON.parse(await localStorage.getItem('_cdi'));
    this.dados.empresa = local.empresa.id;
    this.auth = this.$route.params.auth;

    let df = new Date(Date.now());
    let di = new Date();
    di.setDate(df.getDate() - 30);

    this.dados.item.dataInicial = dataHelper.retornaDataYYYYMMDD(di);
    this.dados.item.dataFinal = dataHelper.retornaDataYYYYMMDD(df);

    this.listarDados(1);
  }
}
</script>

<style lang="scss" scoped>
  @import "@/common/scss/breakpoints.scss";

  .filtro {
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: $medium-size) {
      width: 100%;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 0.8em;
    }
  }

  .alerta {
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: $medium-size) {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0.8em;
    }

    &Texto {
      @media (min-width: $medium-size) {
        grid-column: 1 / 6;
      };
      font-size: 14px;
    }

    &Botao {
      @media (min-width: $medium-size) {
        grid-column: 7 / 9;
      }
    }
  }
</style>